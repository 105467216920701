@import 'react-toastify/dist/ReactToastify.css';

@tailwind base;

@tailwind components;

@tailwind utilities;

@layer components {
  /*custom header styles*/
  .custom-hdr {
    position: 'relative';
    box-shadow: theme('boxShadow.sm');
    height: theme('spacing.14');
    background: theme('colors.white');
  }

  .loading {
    position: absolute;
    top: 50%;
    left: -30%;
    margin-top: 20px;
    width: 100px;
  }

  .loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis infinite;
    animation: ellipsis infinite;
    animation-duration: 1.5s;
    content: ' \2026';
    /* ascii code for the ellipsis character */
    width: 0px;
  }

  .logo-bg {
    background-image: url('assets/new-icons/logo-bg.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .categories-bg {
    background-image: url('assets/images/categories-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .markdown > * {
    all: revert;
  }

  @keyframes ellipsis {
    0% {
      width: 0px;
      opacity: 1;
    }
    50% {
      width: 15px;
      opacity: 1;
    }
    80% {
      width: 25px;
      opacity: 0.3;
    }
    100% {
      width: 30px;
      opacity: 0;
    }
  }

  @-webkit-keyframes ellipsis {
    0% {
      width: 0px;
      opacity: 1;
    }
    50% {
      width: 15px;
      opacity: 1;
    }
    80% {
      width: 25px;
      opacity: 0.3;
    }
    100% {
      width: 30px;
      opacity: 0;
    }
  }
}

@layer base {
  /*main font families*/
  .font-family {
    font-family: theme('fontFamily.main');
    font-weight: 900;
  }
  /*main font sizes*/
  .font-xs {
    font-size: theme('fontSizes.xxs');
    line-height: theme('fontSizes.xs');
  }
  .font-sm {
    font-size: theme('fontSizes.xs');
    line-height: theme('fontSizes.md');
  }
  .font-regular {
    font-size: theme('fontSizes.md');
    line-height: theme('fontSizes.lg');
  }
  .font-large {
    font-size: theme('fontSizes.regular');
    line-height: theme('fontSizes.xl');
  }
  /*main headings sizes*/
  .font-h-1 {
    font-size: theme('fontSizes.3xl');
    line-height: theme('fontSizes.4xl');
  }
  .font-h-2 {
    font-size: theme('fontSizes.2xl');
    line-height: theme('fontSizes.3xl');
  }
  .font-h-3 {
    font-size: theme('fontSizes.lg');
    line-height: theme('fontSizes.2xl');
  }
  /*main grids that not included in Tailwind*/
  .grid-cols-inherit {
    grid-template-columns: inherit;
  }
  /*main grids*/
  .w-available {
    width: -webkit-fill-available;
  }
}
