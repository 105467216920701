#root:has(.login-layout-wrapper) {
  position: relative !important;
}

#okta-sign-in,
#okta-sign-in.auth-container.main-container {
  font-family: inherit;
  border: none;
  box-shadow: none;
  background-color: transparent;
  margin: 0;
  width: auto;
  overflow: hidden;
  min-width: auto;
}

#okta-sign-in .auth-content:has(.primary-auth) {
  padding: 0 !important;
}

#okta-sign-in .auth-content:has(.mfa-verify) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#okta-sign-in .auth-content:has(.mfa-verify) .okta-form-title {
  display: none;
}

body:has(.primary-auth) .custom-footer {
  display: flex;
}

#okta-sign-in.auth-container:has(.primary-auth) .auth-header {
  display: none !important;
}

#okta-sign-in.auth-container:has(.mfa-verify) .auth-header {
  margin-bottom: 52px;
}

#okta-sign-in.auth-container input[type='button'],
#okta-sign-in.auth-container input[type='submit'] {
  font-family: inherit;
}

#okta-sign-in.auth-container .okta-form-label label {
  font-weight: 600;
  font-size: 16px !important;
  color: '#424242' !important;
}

#okta-sign-in .o-form-head + .o-form-explain,
#okta-sign-in .enroll-activation-link-sent p {
  text-align: left;
}

#okta-sign-in .okta-form-title {
  font-weight: 600;
  font-size: 24px;
  color: #002d5d !important;
}

#okta-sign-in .okta-form-input-field,
#okta-sign-in.auth-container .chzn-single {
  border: 1px solid #c0cbd8;
  transition: 150ms;
  border-radius: 6px;
}

#okta-sign-in .okta-form-input-field:has(.phoneInput) {
  border-radius: 0 !important;
}

#okta-sign-in .okta-form-input-field input {
  border-radius: 6px !important;
}

#okta-sign-in .okta-form-input-field:hover,
#okta-sign-in.auth-container .chzn-single:hover {
  border-color: #506d95 !important;
}

#okta-sign-in .okta-form-input-field:focus-within,
#okta-sign-in.auth-container .chzn-single:focus-within,
.chzn-container-single .chzn-drop,
.chzn-container-active.chzn-with-drop .chzn-single {
  border-color: #184072 !important;
}

#okta-sign-in .o-form-input-name-phoneNumber {
  border: none !important;
}

#okta-sign-in .okta-form-input-field.o-form-input-name-phoneNumber input,
#okta-sign-in .okta-form-input-field.o-form-input-name-phoneNumber {
  border-radius: 0 !important;
}

@media (max-width: 767px) {
  #okta-sign-in .okta-form-title {
    font-size: 20px;
  }

  #okta-sign-in .auth-content:has(.primary-auth) {
    min-width: 287px;
  }
}

@media (max-width: 1024px) {
  .login-layout-wrapper {
    background-image: none !important;
    background-color: #f5f8fa !important;
  }

  #okta-sign-in.auth-container .auth-content {
    max-width: 100% !important;
  }
}
