#okta-sign-in {
  font-size: 16px;
}

#okta-sign-in .focused-input,
#okta-sign-in .link.help:focus {
  box-shadow: none;
}

#okta-sign-in.auth-container .okta-form-input-field.o-form-has-errors {
  border-color: #dc3545 !important;
}

#okta-sign-in.auth-container .okta-form-input-error {
  font-size: 14px;
  padding: 0;
}

#okta-sign-in.auth-container .o-form-explain.okta-form-input-error {
  padding-top: 4px;
}

#okta-sign-in.auth-container .auth-passcode {
  width: auto;
}

#okta-sign-in.auth-container .okta-form-input-error .icon,
#okta-sign-in .auth-footer {
  display: none !important;
}

#okta-sign-in .app-download-instructions .instructions-title,
#okta-sign-in .enroll-factor-row .enroll-factor-label,
#okta-sign-in .barcode-scan .okta-form-subtitle {
  text-align: left;
  color: #002d5d;
  font-family: Barlow, system-ui, sans-serif;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 8px;
}

#okta-sign-in .enroll-factor-list .list-title {
  display: none;
}

#okta-sign-in .scan-instructions .scan-instructions-qrcode-wrapper {
  clear: both;
  float: none;
}

#okta-sign-in .scan-instructions .scan-instructions-details-wrapper {
  margin-right: 0;
  margin-bottom: 16px;
}

#okta-sign-in .scan-instructions .scan-instructions-details {
  padding-right: 0;
}

#okta-sign-in.auth-container .button-primary {
  border: none;
  outline: none;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
  line-height: 20px;
  transition: 150ms;
  color: #002d5d;
  background: #b6de3a;
}

#okta-sign-in.auth-container .inline-totp-verify.link-button,
#okta-sign-in.auth-container .enroll-factor-button.link-button {
  border: 1px solid #8ea4be !important;
  display: flex !important;
  align-items: center;
}

#okta-sign-in.auth-container .button-primary:focus {
  background: #98c804;
  border: 2px solid #e2f8a0;
}

#okta-sign-in.auth-container .button-primary:hover {
  background: #98c804;
}

#okta-sign-in.auth-container .button-primary:active {
  background: #98c804;
  border: 2px solid #e2f8a0;
}

#okta-sign-in.auth-container .button-primary:disabled {
  background: #e2f8a0 !important;
  color: #6d8aab !important;
}

#okta-sign-in.auth-container .forgot-password h2,
#okta-sign-in.auth-container .mfa-verify h2 {
  font-size: 18px;
  margin-bottom: 8px;
}

#okta-sign-in .custom-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

#okta-sign-in .custom-checkbox input {
  opacity: 1;
  position: relative;
  inset: 0;
  margin: inherit !important;
}

#okta-sign-in .custom-checkbox label {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
  cursor: pointer;
  color: #002d5d !important;
  font-size: 14px !important;
  height: auto;
}

#okta-sign-in .custom-checkbox label.checked {
  font-weight: 600;
}

#okta-sign-in .custom-checkbox input[type='checkbox'] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #c0cbd8 !important;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: 150ms;
}

#okta-sign-in .custom-checkbox input[type='checkbox']:hover {
  border-color: #002d5d !important;
}

#okta-sign-in .custom-checkbox input[type='checkbox']:checked {
  background-color: #002d5d;
  border-color: #002d5d !important;
  position: relative;
}

#okta-sign-in .custom-checkbox input[type='checkbox']:checked::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

#okta-sign-in .custom-checkbox input[type='checkbox']:disabled {
  border-color: #b3b3b3;
  background-color: #d9d9d9;
  cursor: not-allowed;
}

#okta-sign-in
  #okta-sign-in
  .okta-form-input-field:has(.o-form-input-name-phoneNumber)
  input {
  border-radius: 0 !important;
}
